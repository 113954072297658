import Modal from "@components/Modal"
import {useAtom} from "jotai"
import {KeyEnabledPastes, keySettings} from "@features/keys/Keys.atom"
import React, {useEffect, useMemo, useState} from "react"
import {setPasteKeyExpireTime, setPasteKeyName,} from "@features/keys/api/ManagePasteKeys"
import {getToken} from "@features/users/account/Account.atom"
import {toast} from "react-hot-toast"
import Button from "@components/inputs/Button"
import {useNavigate} from "react-router-dom"
import ModifiableProperty from "@components/property/ModifiableProperty"
import GetKeyEnabledPaste from "@features/keys/api/models/responses/GetKeyEnabledPaste"
import {statusReport} from "@features/status/Status.atom"

/**
 * Manage settings for a key enabled paste.
 */
const KeySettings = () => {
    const nav = useNavigate()

    const [report] = useAtom(statusReport)

    const [data, setData] = useAtom(KeyEnabledPastes)
    const [usingPreset, setUsingPreset] = useState(false)

    const [paste, setPaste] = useAtom(keySettings)

    const visible = useMemo(() => paste !== undefined, [paste])

    const [hours, setHours] = useState<number>()
    const [name, setName] = useState<string | undefined>(undefined)
    const [adConfig, setAdConfig] = useState<number>()

    useEffect(() => {
        if (paste) {
            setHours(paste.key.expireTimeMs / 3.6e6)
            setName(paste.key.keyName)
            setAdConfig(paste.key.adConfig)
        }
    }, [paste])

    const [session] = useAtom(getToken)
    const [status] = useAtom(statusReport)

    if (!status) return <></>

    // wait until stuff loads :)
    if (!paste || !hours || name === undefined || !adConfig) {
        return <></>
    }

    // when the settings button is closed
    const goSettings = () => {
        nav(`/edit/${paste.paste.pasteID}`)
        setPaste()
    }

    // find a key by it's ID and update it's instance in the atom
    const findAndUpdateKey = (
        pasteID: string,
        onFind: (oldKey: GetKeyEnabledPaste) => GetKeyEnabledPaste
    ) => {
        setData((prevData) => {
            const keys = Object.keys(prevData)

            for (let i = 0; keys.length > i; i++) {
                const page = prevData[i + 1]
                const filteredResult = page.filter(
                    (key) => key.paste.pasteID === pasteID
                )

                // this means that the key was found
                if (filteredResult.length > 0) {
                    const oldKey = filteredResult[0] // old key
                    const oldKeyIndex = page.indexOf(oldKey) // old key index

                    // newly made key, provided old key
                    page[oldKeyIndex] = onFind(oldKey)

                    return {
                        ...prevData,
                        [i + 1]: page,
                    }
                }
            }

            return prevData
        })
    }

    // update the paste key expire time
    const onSaveExpirationTime = async () => {
        if (hours) {
            try {
                await setPasteKeyExpireTime(
                    session,
                    paste.paste.pasteID,
                    +hours
                )

                findAndUpdateKey(paste.paste.pasteID, (key) => ({
                    ...key,
                    key: {
                        ...key.key,
                        // hours into ms
                        expireTimeMs: hours * 60 * 60 * 1000,
                    },
                }))

                toast.success("Updated your expire time!")
            } catch (e) {
                toast.error(`${e}`)
            }
        }
    }

    // update the paste key name
    const onSaveName = async () => {
        if (name) {
            try {
                await setPasteKeyName(session, paste.paste.pasteID, name)

                findAndUpdateKey(paste.paste.pasteID, (key) => ({
                    ...key,
                    key: {
                        ...key.key,
                        keyName: name,
                    },
                }))

                toast.success("Updated key's name!")
            } catch (e) {
                toast.error(`${e}`)
            }
        }
    }

    return (
        <Modal
            visible={visible}
            setVisible={() => setPaste()}
            title={"Key Settings"}
        >
            <form className="p-4">{/* TODO: Monetize */}</form>

            <ModifiableProperty
                onSave={onSaveName}
                title={"Name"}
                status={name ? name : "No name"}
                value={name}
                setValue={setName}
                inputProps={{ placeholder: "Key name" }}
                editText={
                    <>
                        <i className="fa-solid fa-pencil pr-2" /> Change
                    </>
                }
                completeText={
                    <>
                        <i className="fa-solid fa-save pr-2" /> Save
                    </>
                }
            />

            <ModifiableProperty
                onSave={onSaveExpirationTime}
                title={"Expiration in Hours"}
                status={`${hours} hours`}
                value={hours ? `${hours}` : undefined}
                setValue={(e) => setHours(+e)}
                inputProps={{ type: "number", placeholder: "Time in Hours" }}
                editText={
                    <>
                        <i className="fa-solid fa-pencil pr-2" /> Change
                    </>
                }
                completeText={
                    <>
                        <i className="fa-solid fa-save pr-2" /> Save
                    </>
                }
            />

            <div className="mt-4 flex flex-row gap-4">
                <Button onClick={goSettings} buttonStyle={"primary"}>
                    Paste Settings
                </Button>

                <Button onClick={() => setPaste()} buttonStyle={"secondary"}>
                    Close
                </Button>
            </div>
        </Modal>
    )
}

export default KeySettings
