import { useAtom } from "jotai"
import {
    keyCreationAdConfig,
    keyCreationExpiration,
    keyCreationName,
    keyCreationProcess,
} from "@features/keys/Keys.atom"
import Modal from "@components/Modal"
import Input from "@components/inputs/Input"
import Label from "@components/inputs/Label"
import React, { useState } from "react"
import PasteMonetization from "@features/pastes/components/editor/PasteMonetization"
import Button from "@components/inputs/Button"
import toast from "react-hot-toast"
import { statusReport } from "@features/status/Status.atom"

const KEY_NAME_REGEX = new RegExp("[\\sA-Za-z_$&+,:;=?@#|'<>.^*()%!-0-9~]+")

/**
 * Select name and expiration date for a key.
 * Step 2 in {@link keyCreationProcess}.
 */
export default function KeyDetailSelection() {
    const [step, setStep] = useAtom(keyCreationProcess)

    const [hours, setHours] = useAtom(keyCreationExpiration)
    const [name, setName] = useAtom(keyCreationName)

    // when going next, ensure entered details are valid
    const goNext = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (0 >= hours) {
            toast.error("Expiration time cannot be under an hour!")
            return
        }

        if (168 < hours) {
            toast.error("Expiration time cannot be over a week!")
            return
        }

        if (name !== "" && !KEY_NAME_REGEX.test(name)) {
            toast.error("Invalid characters in key name!")
            return
        }

        if (name.length > 32) {
            toast.error("Key name must be under 32 characters!")
            return
        }

        setStep(2)
    }

    return (
        <Modal
            visible={step === 1}
            setVisible={() => setStep(0)}
            title={"Create Key"}
            seriesTransitioning={true}
            sizing={"xsmall"}
        >
            <form
                className="flex flex-col gap-4 px-8"
                onSubmit={(form) => goNext(form)}
            >
                <Label label="Key name">
                    <Input
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                        placeholder="Key name here"
                    />
                </Label>

                <Label label="Key Lifespan">
                    <Input
                        value={hours}
                        onChange={(value) => {
                            if (!isNaN(+value.currentTarget.value)) {
                                setHours(+value.currentTarget.value)
                            } else {
                                value.preventDefault()
                            }
                        }}
                        type="text"
                        iconRight={<span className="text-gray-400">Hours</span>}
                    />
                </Label>

                <PasteMonetization extendSize defaultChecked={true} />

                <div className="flex flex-row justify-between">
                    <Button
                        buttonStyle="secondary"
                        onClick={() => {
                            setStep(0)
                        }}
                    >
                        <i className="fa-solid fa-arrow-left" /> Back
                    </Button>

                    <div className="relative">
                        <Button buttonStyle="primary" type="submit">
                            Details <i className="fa-solid fa-arrow-right" />
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}
