import React, {useEffect} from "react"
import {createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider,} from "react-router-dom"
import Footer from "@features/layout/components/Footer"
import Header from "@features/layout/components/Header"
import Home from "./views/Home"
import {useAtom} from "jotai"
import Edit from "./views/pastes/Edit"
import View from "./views/pastes/View"
import {Toaster} from "react-hot-toast"
import NotFound from "./views/errors/NotFound"
import User from "./views/User"
import Dashboard from "./views/account/Dashboard"
import Register from "./views/account/Register"
import ToS from "./views/legal/ToS"
import Privacy from "./views/legal/Privacy"
import Legal from "./views/legal/Legal"
import Crypto from "@views/guides/Crypto"
import {AccountStore} from "@features/users/account/api/models/AccountStore"
import {account, getToken, setToken,} from "@features/users/account/Account.atom"
import Interstitial from "@views/pastes/Interstitial"
import Key from "@views/pastes/Key"
import KeyApi from "@views/guides/KeyApi"
import ErrorElement from "@views/errors/ErrorElement"
import Seo from "@features/layout/components/Seo"
import RegisterAlias from "@views/account/RegisterAlias"
import ResetPassword from "@views/account/ResetPassword"
import Support from "@views/Support"
import {statusReport} from "@features/status/Status.atom"
import {getStatusReport} from "@features/status/api/Status"
import Landing from "@views/Landing"

/**
 * Base element for pages.
 */
const Base = () => (
    <div>
        <Header />

        <div id="content" className="max-w-screen-2xl 2xl:m-auto">
            <Outlet />
        </div>

        <Footer />
    </div>
)

/**
 * Routes
 */
const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<Base />} errorElement={<ErrorElement />}>
                <Route index element={<Home />} />

                <Route path="/landing" element={<Landing />} />

                <Route path="/account/dashboard" element={<Dashboard />} />

                <Route path="/legal/privacy" element={<Privacy />} />
                <Route path="/legal/tos" element={<ToS />} />
                <Route path="/legal" element={<Legal />} />

                <Route path="/crypto" element={<Crypto />} />
                <Route path="/keyapi" element={<KeyApi />} />

                <Route path="/support" element={<Support />} />

                <Route path="/@:username" element={<User />} />
                <Route path="/register" element={<Register />} />
                <Route path="/r" element={<RegisterAlias />} />

                <Route path="/reset-password" element={<ResetPassword />} />

                <Route path="/:id" element={<View />} />
                <Route path="/interstitial/:id" element={<Interstitial />} />
                <Route path="/key/:token" element={<Key />} />
                <Route path="/edit/:id" element={<Edit />} />

                <Route path="*" element={<NotFound />} />
            </Route>
        </>
    )
)

/**
 * The main App.
 * This holds router functions, as well as session token stuff.
 */
export const App = () => {
    const [sesToken] = useAtom(getToken)
    const [, setSesToken] = useAtom(setToken)
    const [, setAcc] = useAtom(account)
    const [, setStatusReport] = useAtom(statusReport)

    // Loads new account details when the session token changes
    useEffect(() => {
        const loadStatus = async () => {
            try {
                const { user, banner, placementDetails } =
                    await getStatusReport(sesToken)

                setStatusReport({ banner, placementDetails })

                if (!user) {
                    setAcc(undefined)
                    setSesToken("")
                    return
                }

                // ...prev because sometimes the user balance is loaded before the account
                setAcc(
                    (prev) =>
                        ({ ...prev, ...user, ...user?.user } as AccountStore)
                )
            } catch (e) {
                // this means that the session is invalid, which most likely means the user's token expired
                setAcc(undefined)
                setSesToken("")
            }
        }

        if (sesToken !== "") {
            loadStatus()
        } else {
            // this doesn't need to wait, since it's not VITAL to the page running.
            getStatusReport().then((report) => setStatusReport(report))
        }
    }, [sesToken, setAcc, setSesToken, setStatusReport])

    return (
        <main className="text">
            <Seo />

            <Toaster
                toastOptions={{
                    className: "primaryBackground text",
                    duration: 4000,
                }}
            />

            <RouterProvider router={router} />
        </main>
    )
}
