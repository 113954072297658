import React, { useState } from "react"
import toast from "react-hot-toast"

/**
 * Props for individual tags.
 *
 * @param tagName The name of the tag.
 * @param onRemove When the tag is attempting to remove itself.
 */
type TagProps = {
    tagName: string
    onRemove: (name: string) => void
}

/**
 * An individual tag itself.
 */
const Tag: React.FC<TagProps> = ({ tagName, onRemove }) => {
    return (
        <div className="flex items-center justify-center island-noshadow shadow-sm rounded-lg px-1 gap-2 flex-row">
            <p>
                {tagName.length > 5 ? `${tagName.substring(0, 5)}...` : tagName}
            </p>

            <button type="button">
                <i
                    onClick={() => onRemove(tagName)}
                    className="fa-solid fa-x pr-1 text-[10px] pb-[5.4px]"
                ></i>
            </button>
        </div>
    )
}

/**
 * Props for tags
 */
type TagsProps = {
    tags: string[]
    setTags: React.Dispatch<React.SetStateAction<string[]>>
}

/**
 * Tags
 */
const Tags: React.FC<TagsProps> = ({ tags, setTags }) => {
    const [tagValue, setTagValue] = useState<string>("")

    // removes the tag with the specified name
    const onRemove = (name: string) => {
        setTags((tags) => tags.filter((value) => value !== name))
    }

    const submitTag = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        // remove the latest tag with a backspace (and no content)
        if (ev.key === "Backspace" && tagValue.length === 0) {
            ev.preventDefault()

            setTags((tags) => tags.slice(0, -1))
        }

        // create a tag on enter
        if (ev.key === "Enter") {
            ev.preventDefault()

            if (tags.length >= 10) {
                toast.error("You can only have 10 tags!")
                return
            }

            if (tags.includes(tagValue)) {
                toast.error("You already have a tag with that name!")
                return
            }

            if (tagValue === "" || !tagValue.match("^[a-zA-Z0-9_ ]*$")) {
                toast.error("Invalid tag characters!")
                return
            }

            if (tagValue.length > 32) {
                toast.error("Tags must be under 32 characters!")
                return
            }

            setTags((tags) => [...tags, tagValue])
            setTagValue("")
        }
    }

    return (
        <div className="flex flex-row textInput">
            <div className="flex flex-row gap-1">
                {tags.slice(-3).map((tag) => (
                    <Tag tagName={tag} onRemove={onRemove} />
                ))}
            </div>

            <input
                className="bg-transparent w-full h-full focus:outline-none"
                onChange={(e) => setTagValue(e.target.value)}
                value={tagValue}
                onKeyDown={submitTag}
                placeholder={" Tag name here"}
            />
        </div>
    )
}

/**
 * Use tags.
 *
 * @param initTags Initial tags.
 */
const useTags = (
    initTags?: string[]
): [string[], JSX.Element, React.Dispatch<React.SetStateAction<string[]>>] => {
    const [tags, setTags] = useState<string[]>(initTags ?? [])

    return [tags, <Tags tags={tags} setTags={setTags} />, setTags]
}

export default useTags
