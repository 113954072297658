import { useAtom } from "jotai"
import React, { ChangeEvent, useEffect, useState } from "react"
import toast from "react-hot-toast"
import QRCode from "react-qr-code"
import { getMfaCode, toggleMfa } from "@features/users/login/api/Mfa"
import {
    account,
    getToken,
    mfaToggleStatus,
    mfaToggleVisible,
} from "@features/users/account/Account.atom"
import { AccountStore } from "@features/users/account/api/models/AccountStore"
import Spinner from "@components/Spinner"
import Modal from "@components/Modal"
import Button from "@components/inputs/Button"
import Input from "@components/inputs/Input"

/**
 * The enable MFA modal.
 */
const ToggleMfa = () => {
    const [status] = useAtom(mfaToggleStatus)

    // what the user's attempting to do
    // this is reused throughout this function
    const type = status ? "Enable" : "Disable"

    // if the toggle MFA is visible
    const [visible, setVisible] = useAtom(mfaToggleVisible)

    // this is the user's secret MFA code.
    // this is only used when status is true, and the
    // qr code needs to be generated.
    const [mfaCode, setMfaCode] = useState<string>()

    // user's details
    const [acc, setAcc] = useAtom(account)
    const [sesToken] = useAtom(getToken)

    const [loading, setLoading] = useState(false)

    const [userCode, setUserCode] = useState("")

    // this loads the user's MFA code
    // only used when the status is true and a QR code needs to be generated
    useEffect(() => {
        if (visible && status) {
            const loadMfaCode = async () => {
                try {
                    const req = await getMfaCode(sesToken)

                    setMfaCode(req)
                } catch (e) {
                    toast.error(
                        "There was an issue setting up 2FA, please try again later."
                    )

                    setVisible(false)
                }
            }

            loadMfaCode()
        }
    }, [sesToken, setVisible, status, visible])

    // when the user hits the submit button
    // this either enables or disabled 2fa depending on what
    // the status is
    const submit = async () => {
        setUserCode("")
        setLoading(true)

        try {
            await toggleMfa(sesToken, status, userCode)

            toast.success(`2FA has been successfully ${type.toLowerCase()}d!`)
            setVisible(false)
            setAcc(
                (prev) =>
                    ({
                        ...prev,
                        mfaEnabled: status,
                    } as AccountStore)
            )
        } catch (e) {
            toast.error(`${e}`)
        }

        setLoading(false)
    }

    // when the code is updated
    // this only lets the user type numbers
    const updateCode = (e: ChangeEvent<HTMLInputElement>) => {
        const newCode = e.currentTarget.value.replace(/\D/g, "")

        setUserCode(newCode)
    }

    // only show if mfa isn't already enabled
    return (
        <Modal visible={visible} setVisible={setVisible} title={`${type} 2FA`}>
            <div className="flex flex-col items-center justify-center">
                {/* Show QR code if the user's attempting to enable MFA */}
                {status &&
                    (!mfaCode ? (
                        <Spinner />
                    ) : (
                        <div className="border-8 rounded-lg border-white">
                            <QRCode
                                value={`otpauth://totp/BloxyBin: ${acc?.username}?secret=${mfaCode}&issuer=BloxyBin`}
                            />
                        </div>
                    ))}

                <div className="mt-4 gap-2 flex flex-col">
                    {/* 2FA code input */}
                    <Input
                        type="text"
                        name="code"
                        placeholder="2FA Code here"
                        maxLength={6}
                        value={userCode}
                        onChange={updateCode}
                        required={true}
                        onEnter={submit}
                    />

                    {/* Submit button */}
                    <Button onClick={submit}>
                        {type} {loading && <Spinner />}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ToggleMfa
