import React from "react"
import {
    StatisticsReport,
    TimeRangeReport,
} from "@features/users/account/api/models/responses/GetStatistics"
import TimeRangeCard, { TimeRangeCardSkeleton } from "@components/TimeRangeCard"

const PasteViews: React.FC<{ stats?: StatisticsReport }> = ({ stats }) => {
    if (!stats) {
        return <TimeRangeCardSkeleton className="lg:w-1/3" title={"Paste Views"} />
    }

    const getStatistics = async (range: string): Promise<TimeRangeReport> => {
        switch (range) {
            case "yesterday":
                return stats.yesterday
            case "weekly":
                return stats.weekly
            default:
                return stats.lifetime
        }
    }

    return (
        <TimeRangeCard
            className="lg:w-1/3"
            title={"Paste Views"}
            defaultRange={"yesterday"}
            initialAmount={stats.yesterday.total}
            onUpdateRange={(range) => getStatistics(range).then(stat => stat.total)}
            units="views"
            ranges={{
                yesterday: "Yesterday",
                weekly: "Weekly",
                lifetime: "Lifetime",
            }}
        />
    )
}

export default PasteViews
