import { atom, useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Modal from "@components/Modal"
import Spinner from "@components/Spinner"
import Paginator from "@components/Paginator"
import { getPayoutHistory } from "@features/users/account/api/Statistics"
import { getToken, isLoggedIn } from "@features/users/account/Account.atom"
import { Withdrawal } from "@features/users/account/api/models/Payout"
import toast from "react-hot-toast"

/**
 * A specific payout entry.
 */
const PayoutEntry: React.FC<{ payout: Withdrawal; index: number }> = ({
    payout,
    index,
}) => {
    let moneyText

    switch (payout.type) {
        case "MONERO":
            moneyText = `${(payout.xmrAmount ?? 0) / Math.pow(10, 12)}XMR`
            break
        case "PAYPAL":
            moneyText = `$${payout.totalAmount}`
            break
        case "ROBUX":
            moneyText = `${payout.totalAmount} robux`
            break
    }

    return (
        <tr
            className={`${
                index % 2 === 0 ? "secondaryBackground" : "primaryBackground"
            } border-b border-stone-300 dark:border-stone-800`}
        >
            <th
                scope="row"
                className="whitespace-nowrap py-4 px-6 font-medium text-gray-900 dark:text-white"
            >
                {new Date(payout.date).toLocaleString()}
            </th>
            <td className="max-w-xs break-words py-4 px-6">
                {payout.paymentAddress}
            </td>
            <td className="py-4 px-6">{moneyText}</td>
            <td className="py-4 px-6">{payout.type}</td>
            <td className="py-4 px-6">
                <b>{payout.status}</b>
            </td>
        </tr>
    )
}

/**
 * If the withdraw history modal is visible.
 */
export const withdrawHistoryVisible = atom(false)

/**
 * A table of a user's payout history.
 */
const WithdrawHistory = () => {
    const [visible, setVisible] = useAtom(withdrawHistoryVisible)

    const [results, setResults] = useState({} as any)

    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [totalResults, setTotalResults] = useState(0)

    // default stuff
    const [sesToken] = useAtom(getToken)
    const [loggedIn] = useAtom(isLoggedIn)
    const nav = useNavigate()

    // load the payout history
    useEffect(() => {
        const loadPayoutHistory = async (pageNumber: number) => {
            try {
                const req = await getPayoutHistory(sesToken, pageNumber)

                setResults((prev: any) => ({
                    ...prev,
                    [pageNumber]: req.pageContents,
                }))

                setMaxPage(req.maxPage)
                setTotalResults(req.totalResults)
            } catch (e) {
                toast.error(`${e}`)
            }
        }

        if (!results.hasOwnProperty(page) && visible) {
            loadPayoutHistory(page).then(() =>
                console.log("Completed loading payout history!")
            )
        }
    }, [sesToken, page, results, visible])

    // go back home if the user's not logged in
    if (!loggedIn) {
        nav("/")
    }

    return (
        <Modal
            sizing={"large"}
            visible={visible}
            setVisible={setVisible}
            title={"Withdraw History"}
        >
            <div className="relative mt-5 self-center overflow-hidden">
                {results.hasOwnProperty(page) ? (
                    <>
                        {/* Table of payout history */}
                        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
                            {/* Table head, showing date, amount, and payment address */}
                            <thead className="primaryBackground text-xs uppercase text-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="py-3 px-6">
                                        Date
                                    </th>

                                    <th scope="col" className="py-3 px-6">
                                        Address
                                    </th>

                                    <th scope="col" className="py-3 px-6">
                                        Amount
                                    </th>

                                    <th scope="col" className="py-3 px-6">
                                        Type
                                    </th>

                                    <th scope="col" className="py-3 px-6">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* parse through all payouts, turning into a table row. */}
                                {results[page]?.map(
                                    (withdrawal: Withdrawal, index: number) => (
                                        <PayoutEntry
                                            index={index}
                                            key={withdrawal.withdrawalID}
                                            payout={withdrawal}
                                        />
                                    )
                                )}
                            </tbody>
                        </table>
                        <Paginator
                            currentPage={page}
                            setCurrentPage={setPage}
                            maxPage={maxPage}
                            totalResults={totalResults}
                            pageCount={10}
                        />
                    </>
                ) : (
                    <div className="flex items-center justify-center">
                        <Spinner />
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default WithdrawHistory
