import { useAtom } from "jotai"
import UserDropDown, {
    userDropDownVisibility,
} from "@features/layout/components/UserDropDown"
import ThemeSelector from "@features/theme/components/ThemeSelector"
import TwoFactor from "@features/users/login/components/TwoFactor"
import LoginModal from "@features/users/login/components/LoginModal"
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom"
import React, { useEffect } from "react"
import Button from "@components/inputs/Button"
import Search from "@features/layout/components/search/Search"
import Spinner from "@components/Spinner"
import { useReferralRegister } from "@features/users/referrals/hooks/useReferralRegister"
import { loginModalVisibility } from "@features/users/login/Login.atom"
import {
    accountBalance,
    isLoggedIn,
} from "@features/users/account/Account.atom"
import ForgotPasswordModal from "@features/users/login/components/ForgotPasswordModal"
import useSeo from "@features/layout/hooks/useSeo"
import toast from "react-hot-toast"
import MobileSearchButton from "@features/layout/components/search/MobileSearchButton"
import MobileSearch from "@features/layout/components/search/MobileSearch"
import RegisterModal from "@features/users/login/components/RegisterModal"

/**
 * The header seen over all pages.
 */
const Header = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    useReferralRegister()

    // The top right dropdown.
    const [, setUserDropDown] = useAtom(userDropDownVisibility)

    const [loggedIn] = useAtom(isLoggedIn)
    const [, setLoginVisible] = useAtom(loginModalVisibility)

    const [balance] = useAtom(accountBalance)

    const nav = useNavigate()
    const location = useLocation()

    // the title of the page
    const [, setTitle] = useSeo()

    useEffect(() => {
        if (searchParams.has("t")) {
            switch (searchParams.get("t")?.toLowerCase()) {
                case "emcf": {
                    toast.success("Your email has been successfully confirmed!")
                    break
                }
            }

            searchParams.delete("t")
            setSearchParams(searchParams)
        }
    }, [searchParams])

    // change the title of the page based on the current location
    useEffect(() => {
        if (location.pathname && location.pathname !== "/") {
            const path = location.pathname.split("/")[1]
            const uppercasePath =
                path[0].toUpperCase() + path.substring(1).toLocaleLowerCase()

            setTitle(`BloxyBin | ${path === "" ? "Home" : uppercasePath}`)
        } else {
            setTitle("BloxyBin | Home")
        }
    }, [location, setTitle])

    return (
        <div className="flex flex-col gap-0 border-b border-gray-200 dark:border-stone-900">
            <nav className="secondaryBackground flex flex-row items-center p-4">
                <div className="container mx-auto flex items-center justify-between">
                    {/* Logo */}
                    <div className="flex flex-col items-center">
                        <Link
                            to="/"
                            className="-mb-1 flex cursor-pointer items-center"
                        >
                            <span className="primary titleFont self-center whitespace-nowrap text-3xl font-bold">
                                <i className="fa-solid fa-scroll"></i> BloxyBin
                            </span>
                        </Link>

                        <p className="poppins text hidden text-sm lg:inline-block">
                            Monetize your Pastes
                        </p>
                    </div>

                    <Search />

                    <div className="flex items-center md:order-2">
                        {/* Various Modals */}
                        <UserDropDown />
                        <RegisterModal />
                        <TwoFactor />
                        <LoginModal />
                        <ForgotPasswordModal />

                        {/* Sign Up and Login Buttons */}
                        {!loggedIn ? (
                            <div className="mr-4 hidden w-full flex-row items-center gap-2 md:flex">
                                <Button
                                    buttonStyle="secondary"
                                    onClick={() => setLoginVisible(true)}
                                >
                                    Login
                                </Button>

                                <Button
                                    buttonStyle="primary"
                                    onClick={() => nav("/register")}
                                >
                                    Sign Up
                                </Button>
                            </div>
                        ) : (
                            <div
                                className={
                                    "hidden flex-col items-center justify-center md:flex"
                                }
                            >
                                <p className={"poppins mx-4"}>
                                    ${balance.toFixed(2) ?? <Spinner />}
                                </p>
                            </div>
                        )}

                        {/* Mobile search dropdown */}
                        <MobileSearchButton />

                        {/* Theme Selector Button */}
                        <ThemeSelector />

                        {/* Account button */}
                        <button
                            className="nav-btn"
                            id="account-button"
                            onMouseDown={() => setUserDropDown((prev) => !prev)}
                        >
                            <i className="fas fa-user text-lg" />
                        </button>
                    </div>
                </div>
            </nav>

            <MobileSearch />
        </div>
    )
}

export default Header
