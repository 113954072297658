import React from "react"
import Button from "@components/inputs/Button"
import { useAtom } from "jotai"
import { withdrawHistoryVisible } from "@features/dashboard/components/WithdrawHistory"
import { accountBalance } from "@features/users/account/Account.atom"
import { MIN_USD_PAYOUT } from "@util/Util"
import { withdrawalProcess } from "@features/users/withdrawals/Withdrawals.atom"
import Card from "@components/Card"

/**
 * Island with withdrawal details.
 *
 * This also holds the modal process for withdrawing, including PayPal, Monero, and Robux.
 */
const WithdrawalIsland = () => {
    const [, setProcess] = useAtom(withdrawalProcess)
    const [, setVisible] = useAtom(withdrawHistoryVisible)
    const [balance] = useAtom(accountBalance)

    return (
        <Card
            title={"Balance"}
            content={`$${balance.toFixed(2)}`}
            contentClassName={`monospace`}
            viewMoreText={"View past"}
            viewMore={() => setVisible(true)}
            loading={balance === -1}
            action={
                <Button
                    disabled={MIN_USD_PAYOUT > balance}
                    onClick={() => setProcess(1)}
                >
                    Withdraw
                </Button>
            }
            className="lg:w-1/3"
        />
    )
}

export default WithdrawalIsland
