import { useAtom } from "jotai"
import React, { FormEvent, useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { referralStorage } from "@features/users/referrals/Referrals.atom"
import { loginModalVisibility } from "@features/users/login/Login.atom"
import {
    account,
    isLoggedIn,
    setToken,
} from "@features/users/account/Account.atom"
import { AccountStore } from "@features/users/account/api/models/AccountStore"
import { LoginResponse } from "@features/users/login/api/responses/LoginResponse"
import Input from "@components/inputs/Input"
import Button from "@components/inputs/Button"
import { register } from "@features/users/login/api/Register"
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile"
import { INVIS_CLOUDFLARE_SITE_KEY } from "@util/Util"

export const ErrorMessages = {
    INVALID_PASSWORD:
        "Your password must have a letter, a number, and at least 8 characters.",
    INVALID_USERNAME:
        "Your username can only contain letters, numbers, and underscores.",
    INVALID_CONFIRM: "Your passwords don't match!",
}

/**
 * The /register page.
 */
const Register = () => {
    const ref = React.useRef<TurnstileInstance>()

    const [password, setPassword] = useState<string>("")
    const [username, setUsername] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")

    const passwordOkay = RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$").test(
        password
    )
    const usernameOkay = RegExp("^[a-zA-Z0-9]+(?:[_]?[a-zA-Z0-9])*$").test(
        username
    )

    const [referral] = useAtom(referralStorage)

    const nav = useNavigate()

    // for the login button
    const [, setLoginVisible] = useAtom(loginModalVisibility)

    // spinner on the button
    const [loading, setLoading] = useState(false)

    // if the user is logged in, return them to main page.
    const [loggedIn] = useAtom(isLoggedIn)

    const [, setAuthToken] = useAtom(setToken)
    const [, setAcc] = useAtom(account)

    const loginButton = () => {
        nav("/")
        setLoginVisible(true)
    }

    // when the form is subbmitted.
    const onSubmit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault()
        setLoading(true)

        let formData = new FormData(ev.currentTarget)

        let username = formData.get("username") as string,
            password = formData.get("password") as string,
            confirmPassword = formData.get("confirmPassword") as string

        const turnstileToken = ref.current?.getResponse()

        if (!confirmPassword || !password || !username) {
            toast.error("Make sure all elements are filled out!")
            setLoading(false)
            return
        }

        // this is checked on backend, but easier to do here as well
        if (confirmPassword !== password) {
            toast.error("Password and Confirm Password must be the same!")
            setLoading(false)
            return
        }

        try {
            const registerResponse = await register(
                username,
                password,
                turnstileToken ?? "",
                referral
            )

            // process is very similar to logging in
            const { token, user } = registerResponse as LoginResponse

            if (token !== "") setAuthToken(token)

            setAcc({
                username: user.user.username,
                userId: user.user.userID,
                email: user.email,
                accountCreation: user.user.registrationDate,
                mfaEnabled: user.mfaEnabled,
            } as AccountStore)

            nav("/")
            toast.success("Welcome to BloxyBin!")
        } catch (e) {
            toast.error(`${e}`)
            setLoading(false)
            ref.current?.reset()
        }
    }

    if (loggedIn) {
        nav("/")
        toast("You're already signed in!")
    }

    return (
        <div className="flex flex-col justify-center items-center mt-4">
            <Turnstile
                id="register"
                options={{
                    size: "invisible",
                }}
                siteKey={INVIS_CLOUDFLARE_SITE_KEY}
                ref={ref}
            />

            <div className=" island p-16 rounded-lg px-24">
                {/* Page title */}
                <h1 className="text-4xl font-semibold poppins mb-6">
                    Create an Account
                </h1>

                <form className="space-y-6" onSubmit={onSubmit}>
                    {/* Username */}
                    <div>
                        <label
                            htmlFor="user"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Username
                        </label>
                        <Input
                            type="user"
                            name="username"
                            id="username "
                            placeholder="Your unique username"
                            value={username}
                            onChange={(e) => setUsername(e.currentTarget.value)}
                            error={
                                username && username !== "" && !usernameOkay
                                    ? ErrorMessages.INVALID_USERNAME
                                    : undefined
                            }
                            required
                        />
                    </div>

                    {/* Password */}
                    <div>
                        <label
                            htmlFor="password"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Password
                        </label>
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Your password"
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            error={
                                password && password !== "" && !passwordOkay
                                    ? ErrorMessages.INVALID_PASSWORD
                                    : undefined
                            }
                            required
                        />
                    </div>

                    {/* Confirm Password */}
                    <div>
                        <label
                            htmlFor="confirmPassword"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Confirm password
                        </label>
                        <Input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="Confirm your password"
                            value={confirmPassword}
                            onChange={(e) =>
                                setConfirmPassword(e.currentTarget.value)
                            }
                            required
                            error={
                                confirmPassword !== "" &&
                                confirmPassword !== password
                                    ? ErrorMessages.INVALID_CONFIRM
                                    : undefined
                            }
                        />
                    </div>

                    {/* Cloudflare turnstile */}
                    <div className="flex flex-col items-center justify-center gap-4">
                        {/* Submit form */}
                        <Button
                            type="submit"
                            className="w-full"
                            loading={loading}
                        >
                            Create account
                        </Button>
                    </div>

                    {/* Offer login if user already has an account */}
                    <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                        Already have an account?{" "}
                        <button
                            onClick={loginButton}
                            className="text-blue-700 hover:underline dark:text-blue-500"
                        >
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Register
