import { useAtom } from "jotai"
import React, { FormEvent, useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { LoginResponse } from "@features/users/login/api/responses/LoginResponse"
import Modal from "@components/Modal"
import Button from "@components/inputs/Button"
import Input from "@components/inputs/Input"
import {
    loginModalVisibility,
    registerModalVisibility,
} from "@features/users/login/Login.atom"
import { AccountStore } from "@features/users/account/api/models/AccountStore"
import { account, setToken } from "@features/users/account/Account.atom"
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile"
import { INVIS_CLOUDFLARE_SITE_KEY } from "@util/Util"
import { register } from "@features/users/login/api/Register"

/**
 * Modal to register new accounts.
 *
 * This appears from the landing page.
 */
export default function RegisterModal() {
    const ref = React.useRef<TurnstileInstance>()

    const nav = useNavigate()

    const [loading, setLoading] = useState(false)

    // Visiblity of the login modal
    const [, setLoginVisible] = useAtom(loginModalVisibility)

    // visibility of the register modal
    const [visible, setVisible] = useAtom(registerModalVisibility)

    // Used in the onSubmit to configure login data\
    const [, setSesToken] = useAtom(setToken)
    const [, setAccStore] = useAtom(account)

    // When the login form is submitted.
    const onSubmit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault()
        setLoading(true)

        let formData = new FormData(ev.currentTarget)

        let username = formData.get("username") as string,
            password = formData.get("password") as string,
            confirmPassword = formData.get("confirmPassword") as string

        const turnstileToken = ref.current?.getResponse()

        if (!confirmPassword || !password || !username) {
            toast.error("Make sure all elements are filled out!")
            setLoading(false)
            return
        }

        // this is checked on backend, but easier to do here as well
        if (confirmPassword !== password) {
            toast.error("Password and Confirm Password must be the same!")
            setLoading(false)
            return
        }

        try {
            const registerResponse = await register(
                username,
                password,
                turnstileToken ?? ""
            )

            // process is very similar to logging in
            const { token, user } = registerResponse as LoginResponse

            if (token !== "") setSesToken(token)

            setAccStore({
                username: user.user.username,
                userId: user.user.userID,
                email: user.email,
                accountCreation: user.user.registrationDate,
                mfaEnabled: user.mfaEnabled,
            } as AccountStore)

            nav("/")
            toast.success("Welcome to BloxyBin!")

            setLoading(false)
            setVisible(false)
        } catch (e) {
            toast.error(`${e}`)
            setLoading(false)
            ref.current?.reset()
        }
    }

    // when the create account button is clicked
    const loginButton = () => {
        setVisible(false)
        setLoginVisible(true)
    }

    return (
        <Modal visible={visible} setVisible={setVisible} title="Register">
            <Turnstile
                id="register-modal"
                options={{
                    size: "invisible",
                }}
                siteKey={INVIS_CLOUDFLARE_SITE_KEY}
                ref={ref}
            />

            <form className="space-y-6" onSubmit={onSubmit}>
                {/* Username input */}
                <div>
                    <label
                        htmlFor="user"
                        className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Username
                    </label>

                    <Input
                        type="text"
                        name="username"
                        id="user"
                        placeholder="Your username"
                        required
                    />
                </div>

                {/* Password input */}
                <div>
                    <label
                        htmlFor="password"
                        className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Password
                    </label>

                    <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Your password"
                        required
                    />
                </div>

                {/* Confirm password input */}
                <div>
                    <label
                        htmlFor="confirmPassword"
                        className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Confirm Password
                    </label>

                    <Input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Confirm your password"
                        required
                    />
                </div>

                <div className="flex flex-col items-center justify-center gap-2">
                    <Button type="submit" loading={loading}>
                        Register
                    </Button>
                </div>

                {/* Not Registered button */}
                <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                    Already have an account?{" "}
                    <button
                        type="button"
                        onClick={loginButton}
                        className="text-blue-700 hover:underline dark:text-blue-500"
                    >
                        Login
                    </button>
                </div>
            </form>
        </Modal>
    )
}
