import { useState } from "react"
import Modal from "@components/Modal"
import Button from "@components/inputs/Button"
import { atom, useAtom } from "jotai"
import toast from "react-hot-toast"
import { account, getToken } from "@features/users/account/Account.atom"
import {
    deleteProfilePicture,
    updateProfilePicture,
} from "@features/users/account/api/UpdateAccount"
import { BASE_URL } from "@util/Util"

/**
 * If the update profile picture modal is visible
 */
export const updateProfilePictureVisibility = atom(false)

/**
 * The modal to update/delete profile pictures.
 */
const UpdateProfilePicture = () => {
    const [visible, setVisible] = useAtom(updateProfilePictureVisibility)
    const [file, setFile] = useState<File | undefined>()

    const [acc] = useAtom(account)

    // when the main modal button is loading
    const [loading, setLoading] = useState(false)

    // if the delete current button is loading
    const [deleteLoading, setDeleteLoading] = useState(false)

    const [ses] = useAtom(getToken)

    // When the user wants to delete their existing profile picture
    const deletePicture = async () => {
        setDeleteLoading(true)

        const success = await deleteProfilePicture(ses)

        if (success) {
            setVisible(false)

            toast.success("Your profile picture has been successfully deleted!")
        } else {
            toast.error("There was an issue deleting your profile picture.")
        }

        setDeleteLoading(false)
    }

    // When the user uploads a file and updates their profile picture.
    const updatePicture = async () => {
        setLoading(true)

        if (!file) {
            toast.error("You must select a picture first!")
            setLoading(false)
            return
        }

        await toast.promise(updateProfilePicture(ses, file), {
            error: (e) => {
                return e
            },
            success: () => {
                setVisible(false)
                return "Successfully updated your profile picture!"
            },
            loading: "Loading...",
        })

        setLoading(false)
    }

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            title={"Update your Profile Picture"}
        >
            {/* User's current profile picture */}
            <div className="flex items-center justify-center">
                <img
                    src={
                        acc?.profile?.profilePicture
                            ? acc?.profile?.profilePicture
                            : `${BASE_URL}/logo512.png`
                    }
                    onError={(e) => (e.currentTarget.hidden = true)}
                    className="rounded-full h-48 aspect-square"
                    alt="User profile"
                />
            </div>

            {/* Image management buttons */}
            <div className="flex flex-row justify-between items-start mt-4">
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => setFile((e.target.files ?? [])[0])}
                />

                {acc?.profile?.profilePicture && (
                    <Button
                        buttonStyle="danger"
                        onClick={deletePicture}
                        loading={deleteLoading}
                    >
                        Remove Current
                    </Button>
                )}
            </div>

            {/* Modal management buttons */}
            <div className="flex flex-row gap-2 mt-6">
                <Button
                    type="button"
                    buttonStyle="primary"
                    loading={loading}
                    onClick={updatePicture}
                >
                    Update
                </Button>

                <Button
                    buttonStyle="secondary"
                    onClick={() => setVisible(false)}
                >
                    Cancel
                </Button>
            </div>
        </Modal>
    )
}

export default UpdateProfilePicture
