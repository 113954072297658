import Card from "@components/Card"
import React from "react"
import { Link } from "react-router-dom"

/**
 * Provides instruction to the user how to integrate keys into their app.
 */
const KeyDeveloperGuide = () => {
    return (
        <Card
            title={"Developer's Guide"}
            content={
                <Link to={"/keyapi"} className="underline text-md">
                    Integrate in your app
                </Link>
            }
        />
    )
}

export default KeyDeveloperGuide