import React, { useMemo, useRef } from "react"
import { Transition } from "@headlessui/react"

/**
 * Props for the Modal class
 *
 * @param visible If the modal is visible.
 * @param setVisible A method that modifies {@param visible}.
 * @param title The title of the mModal.
 * @param children The elements inside of the modal.
 * @param sizing How big the modal should be.
 * @param seriesTransitioning Use a different type of transition that looks more clear when transitioning to and from modals.
 */
interface ModalProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    title: string | JSX.Element
    children: JSX.Element | JSX.Element[] | string
    sizing?: "regular" | "large" | "small" | "xsmall"
    seriesTransitioning?: boolean
}

/**
 * A modal.
 *
 * @see ModalProps
 */
const Modal = ({
    visible,
    setVisible,
    title,
    children,
    sizing,
    seriesTransitioning,
}: ModalProps) => {
    const ref = useRef(null)

    const widthClass = useMemo(() => {
        switch (sizing) {
            case "large":
                return "max-w-6xl"
            case "small":
                return "max-w-md"
            case "xsmall":
                return "max-w-sm"
            default:
                return "max-w-lg"
        }
    }, [sizing])

    return (
        <Transition
            enter={`transition-all ease-in-out ${
                seriesTransitioning ? "duration-200" : "duration-300"
            }`}
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-all ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="z-50 absolute w-0"
            show={visible}
        >
            <div
                className={`z-50 opacity-100 fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full`}
            />

            <div
                className={`fixed inset-0 md:mb-0 mb-[40%] z-50 overflow-y-auto`}
            >
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div
                        ref={ref}
                        className={`${widthClass} my-8 w-full relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all`}
                    >
                        {/* Modal class itself */}
                        <div className="relative rounded-lg shadow primaryBackground">
                            {/* Close button in the top right of modal */}
                            <button
                                onClick={() => setVisible(false)}
                                type="button"
                                className="transition-all absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-secondary_dark dark:hover:text-white"
                                data-modal-toggle="authentication-modal"
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>

                            {/* Content sof the Modal */}
                            <div className="py-6 px-6 lg:px-8">
                                <h3 className="text-center mb-4 text-xl font-medium text-gray-900 dark:text-white">
                                    {title}
                                </h3>

                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    )
}

export default Modal
