import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import toast from "react-hot-toast"
import Spinner from "../components/Spinner"
import { PasteViewCount } from "@features/pastes/api/models/Paste"
import { BASE_URL } from "@util/Util"
import { GetUserResponse } from "@features/users/models/GetUserResponse"
import { getUser } from "@features/users/account/api/ViewAccount"
import { ThumbnailPaste } from "@components/ThumbnailPaste"

/**
 * The /user/{id} page
 */
const User = () => {
    const { username } = useParams()

    // if the user doesn't have a PFP, hide it.
    const [hidePfp, setHidePfp] = useState(false)

    const [user, setUser] = useState<GetUserResponse>()
    const [pastes, setPastes] = useState<PasteViewCount[]>()
    const nav = useNavigate()

    // load the user and their pastes on page load
    useEffect(() => {
        const loadUser = async () => {
            if (!username) {
                nav("/")
                toast.error("That user could not be found!")

                return
            }

            const request = await getUser(username)

            if (!request) {
                nav("/")
                toast.error("That user could not be found!")

                return
            }

            setUser(request)
            setPastes(request.recentPastes.pastes)
        }

        loadUser().then(() => console.log("User has been loaded!"))
    }, [nav, username])

    // if neither have loaded, return a spinner
    if (!user || !pastes) {
        return (
            <div className="flex flex-row justify-center items-center m-14">
                <Spinner />
            </div>
        )
    }

    return (
        <div className="flex flex-col justify-center items-center mt-8">
            <div className="flex flex-row justify-center gap-24 items-center p-6 rounded-xl w-full secondaryBackground">
                <div className="mx-3 relative">
                    <div className="relative mx-3">
                        <img
                            hidden={hidePfp}
                            src={`${BASE_URL}/user/pfp?username=${user.user.username}`}
                            alt="title"
                            className="rounded-3xl mx-auto w-48 h-48"
                            onError={() => setHidePfp(true)}
                        />
                    </div>
                </div>

                <div className="flex flex-col">
                    {/* Large username */}
                    <div className="flex flex-col">
                        <h1 className="text-5xl poppins font-semibold text-center">
                            {user.user.username}
                        </h1>
                    </div>

                    {/* User attributes */}
                    <div className="flex flex-col">
                        <p className="flex flex-row items-center justify-center gap-2 text-sm">
                            <i className="fa fa-clock" /> <b>User Since</b>{" "}
                            {new Date(
                                user.user.registrationDate
                            ).toLocaleDateString()}
                        </p>

                        <p className="pt-5">
                            {user.profile.description ?? "A BloxyBin user"}
                        </p>
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    {user.profile?.youtubeLink && (
                        <a
                            href={user.profile.youtubeLink}
                            target="_blank"
                            rel="noreferrer noopenner"
                            className="standardButtonSizing danger-button"
                        >
                            <i className="fab fa-youtube"></i>
                            &nbsp;&nbsp;YouTube Link
                        </a>
                    )}

                    {user.profile?.discordLink && (
                        <a
                            href={user.profile.discordLink}
                            target="_blank"
                            rel="noreferrer noopenner"
                            className="standardButtonSizing primary-button"
                        >
                            <i className="fab fa-discord"></i>
                            &nbsp;&nbsp;Discord Link
                        </a>
                    )}
                </div>
            </div>

            {/* Most recent pastes */}
            <section className="p-4 pt-10 my-10 flex flex-col mt-5 justify-center items-center secondaryBackground rounded-lg">
                <p className="text-center text-3xl mb-4 poppins font-semibold">
                    Most Recent Pastes
                </p>
                <div className="flex flex-row max-w-4xl justify-center flex-wrap items-center gap-8">
                    {pastes.length > 0 ? (
                        pastes.map((paste) => (
                            <ThumbnailPaste paste={paste.paste} />
                        ))
                    ) : (
                        <p>No pastes yet.</p>
                    )}
                </div>
            </section>
        </div>
    )
}

export default User
