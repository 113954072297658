import {BASE_URL} from "@util/Util"
import Payload from "@api/Payload"
import {API, body, header} from "@util/Api"

/**
 * Update a property of the user's account.
 *
 * @param sessionToken The current session token.
 * @param key The name of the property.
 * @param value The new value.
 */
export const updateAccountInformation = async (
    sessionToken: string,
    key: string,
    value: string
): Promise<void> => {
    const request = await API.post(
        `/user`,
        body({key, value}),
        header(sessionToken, true)
    )
    const {message}: Payload<undefined> = request.data

    if (request.status !== 200) return Promise.reject(message)
}

/**
 * Update user's profile picture.
 * @param session Session token,
 * @param file The new profile picture
 */
export const updateProfilePicture = async (
    session: string,
    file: File
): Promise<void> => {
    const request = await API.post(
        `/user/pfp`,
        file,
        header(session)
    )
    const {message}: Payload<undefined> = request.data

    if (request.status !== 200) return Promise.reject(message)
}

/**
 * Delete the user's profile picture.
 * @param session The user's session
 */
export const deleteProfilePicture = async (
    session: string
): Promise<boolean> => {
    const request = await API.delete(`${BASE_URL}/user/pfp`, header(session))

    return request.status === 200
}

/**
 * Update a user's VIP preferences (ad code)
 * @param session The session token
 * @param adCode The new ad code
 * @returns If the request was successful
 */
export const setVipAdCode = async (
    session: string,
    adCode: string
): Promise<boolean> => {
    const request = await API.post(
        `/user/vip`,
        body({adcode: adCode}),
        header(session, true)
    )

    return request.status === 200
}

/**
 * Delete your own account
 */
export const deleteUserAccount = async (session: string): Promise<boolean> => {
    const request = await API.delete(`${BASE_URL}/user`, header(session))

    return request.status === 200
}

/**
 * Update the user's account monetization status.
 *
 * @param session The session token.
 * @param newStatus The new preset status.
 */
export const updateUserMonetizationStatus = async (
    session: string,
    newStatus: boolean
): Promise<void> => {
    const request = await API.post(
        "/user/ads/monetized",
        body({monetized: `${newStatus}`}),
        header(session, true)
    )

    if (request.status !== 200) {
        const {message}: Payload<undefined> = request.data

        return Promise.reject(message)
    }
}
