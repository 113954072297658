const Privacy = () => {
    return (
        <div className="flex justify-center items-center">
            <div className="flex flex-col max-w-2xl primaryBackground p-4 items-center rounded-lg">
                <h1 className="text-6xl poppins font-bold mb-4">
                    Privacy Policy
                </h1>
                <p>
                    Your privacy is very important to us. Accordingly, we have
                    developed this Policy in order for you to understand how we
                    collect, use, communicate & disclose & make use of personal
                    information. The following outlines our privacy policy.
                </p>

                <ul className="list-disc ml-4 my-4">
                    <li>
                        Before or at the time of collecting personal
                        information, we may identify the purposes for which
                        information is being collected.
                    </li>

                    <li>
                        We may collect & use of personal information, unless as
                        required by law.
                    </li>

                    <li>We may retain personal information indefinitely.</li>

                    <li>
                        Personal data should be relevant to the purposes for
                        which it is to be used, &, to the extent necessary for
                        those purposes, should be accurate, complete, &
                        up-to-date.
                    </li>

                    <li>
                        We may protect personal information by reasonable
                        security safeguards against loss or theft, as well as
                        unauthorized access, disclosure, copying, use or
                        modification.
                    </li>

                    <li>
                        We may make readily available to customers information
                        about our policies & practices relating to the
                        management of personal information.
                    </li>
                </ul>

                <p>
                    We are committed to conducting our business in accordance
                    with these principles in order to ensure that the
                    confidentiality of personal information is protected &
                    maintained.
                </p>
            </div>
        </div>
    )
}

export default Privacy
