import React, {useEffect, useMemo, useState} from "react"
import {useAtom} from "jotai"
import {getToken} from "@features/users/account/Account.atom"
import {GetPasteResponse} from "@features/pastes/api/models/GetPasteResponse"
import {Helmet} from "react-helmet"
import {statusReport} from "@features/status/Status.atom"
import {Link} from "react-router-dom"

/**
 * Properties for the {@link AdHandler}
 *
 * @param preferences Dictates which ads are displayed
 * @param paste If inputted, this will disable specific ad types IF the paste has custom ads enabled.
 */
type AdHandlerProps = {
    preferences: number
    paste?: GetPasteResponse
}

/**
 * This injects ads into the header of the page utilizing `react-helmet`.
 *
 * This is done by checking which placement types are enabled using `preferences,` then through that
 * adding the appropriate scripts.
 */
const AdHandler = ({ preferences, paste }: AdHandlerProps) => {
    const [scripts, setScripts] = useState<JSX.Element[]>([])
    const [status] = useAtom(statusReport)

    // custom will disable some ads
    const usingCustom = useMemo(
        () =>
            paste &&
            paste.customAdCode !== null &&
            paste.customAdCode !== undefined &&
            paste.customAdCode !== "",
        [paste]
    )

    useEffect(() => {
        // wait for the status request to finish before loading in ads
        if (!status) return

        let newScripts: JSX.Element[] = []

        for (let placementType in Object.keys(status.placementDetails)) {
            switch (placementType) {
                // default_src & default are automatically added
                case "DEFAULT_SRC":
                    newScripts = [
                        ...newScripts,
                        <script
                            src={status.placementDetails[placementType]}
                            type="text/javascript"
                        />,
                    ]
                    break
                case "DEFAULT":
                    newScripts = [
                        ...newScripts,
                        <script type="text/javascript">
                            {status.placementDetails[placementType]}
                        </script>,
                    ]
                    break
                // non-default / default_src are not automatically added
                // probably for banner :)
                default:
                    break
            }

            setScripts(newScripts)
        }
    }, [preferences, usingCustom, status])

    return <Helmet>{scripts.map((e) => e)}</Helmet>
}

/**
 * Handles the banner on the paste view page.
 *
 * @see View
 */
const BannerAdHandler = () => {
    const [token] = useAtom(getToken)
    // if we're displaying the bloxybin banner
    const [displayBanner, setDisplayBanner] = useState(true)

    const [status] = useAtom(statusReport)

    useEffect(() => {
        const random = Math.floor(Math.random() * 4) + 1

        // a 1 in 4 change to display the bloxybin banner to non-logged in users
        setDisplayBanner((token === undefined || token === "") && random === 1)
    }, [token, setDisplayBanner])

    // wait for the status & ads to load
    if (!status) return <></>

    const ads = status.placementDetails

    return displayBanner ? (
        //  BloxyBin Banner
        <Link to={"/register"} target={"_blank"}>
            <img
                className="rounded-lg"
                src="/img/banner.png"
                alt={"BloxyBin sign-up banner"}
            />
        </Link>
    ) : (
        <Helmet>
            {/*  300x250 (1) */}
            <script type="text/javascript">{ads["300x250"]}</script>

            {/* Native Banner */}
            {/* This banner is replaced by the BloxyBin banner (when enabled) */}
            {!displayBanner && (
                <script
                    async={true}
                    data-cfasync="false"
                    src={ads["NATIVE_SRC"]}
                />
            )}

            {/* 728 x 90 */}
            <script type="text/javascript">{ads["728x90"]}</script>
        </Helmet>
    )
}

/**
 * Properties for the VIP ad handler.
 *
 * @remark
 * This loads the VIP ad code when it's available.
 *
 * @param paste The paste response. This is used to check if the paste has the custom ad code enabled.
 *
 * @see View
 */
type VipAdHandlerProps = {
    paste: GetPasteResponse
}

/**
 * Handles the custom ad code loading.
 *
 * @see VipAdHandlerProps
 */
const VipAdHandler = ({ paste }: VipAdHandlerProps) => {
    const usingCustom = useMemo(
        () =>
            paste.customAdCode !== null &&
            paste.customAdCode !== undefined &&
            paste.customAdCode !== "",
        [paste]
    )

    return usingCustom ? (
        <Helmet>
            <script data-cfasync="false" src={paste.customAdCode ?? ""} />
        </Helmet>
    ) : (
        <></>
    )
}

AdHandler.Banner = BannerAdHandler
AdHandler.Vip = VipAdHandler

export default AdHandler
