import { useAtom } from "jotai"
import { SeoDescription, SeoTitle } from "@features/layout/Layout.atom"

/**
 * Use the SEO and modify SEO description.
 */
const useSeo = () => {
    const [, setDesc] = useAtom(SeoDescription)
    const [, setTitle] = useAtom(SeoTitle)

    return [(description: string) => setDesc(description), (title: string) => setTitle(title)]
}

export default useSeo