import { useAtom } from "jotai"
import { keyCreationProcess, keySelectedPaste } from "@features/keys/Keys.atom"
import React from "react"
import Modal from "@components/Modal"
import UserPastes from "@features/dashboard/components/UserPastes"
import Button from "@components/inputs/Button"
import { useNavigate } from "react-router-dom"
import { getPasteKey } from "@features/keys/api/ManagePasteKeys"
import { getToken } from "@features/users/account/Account.atom"
import toast from "react-hot-toast"

export default function KeyPasteSelection() {
    const nav = useNavigate()
    const [token] = useAtom(getToken)
    const [step, setStep] = useAtom(keyCreationProcess)

    const [, setSelectedPaste] = useAtom(keySelectedPaste)

    // when a paste is selected
    const onPasteClick = async (pasteID: string, pasteTitle: string) => {
        try {
            // check if a paste already has a key
            await getPasteKey(token, pasteID)

            toast.error("This paste already has a key!")
        } catch (ex) { // will throw exception, if it doesn't exist; we want this
            setSelectedPaste({ pasteID, pasteTitle })
            setStep(3)
        }
    }

    return (
        <Modal
            visible={step === 2}
            setVisible={() => setStep(0)}
            title={"Select Paste to Load"}
            sizing={"large"}
            seriesTransitioning={true}
        >
            <UserPastes
                hideCategories={["manage", "monetized"]}
                titleWidth={"w-1/2"}
                onPasteClick={onPasteClick}
            />

            <div className="flex flex-row justify-between">
                <Button
                    buttonStyle="secondary"
                    onClick={() => {
                        setStep(1)
                    }}
                >
                    <i className="fa-solid fa-arrow-left" /> Back
                </Button>

                <div className="relative">
                    <Button buttonStyle="primary" onClick={() => nav("/")}>
                        Create New Paste
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
