import React from "react"
import EnabledKeyPastes from "@features/keys/components/EnabledKeyPastes"
import KeyCreations from "@features/keys/components/KeyCreations"
import KeyDeveloperGuide from "@features/keys/components/KeyDeveloperGuide"
import KeyAdGuide from "@features/keys/components/KeyAdGuide"

const KeyDashboardTab = () => {
    return (
        <div className="flex flex-col md:flex-row justify-evenly items-center md:items-start">
            <EnabledKeyPastes />

            <div className="flex flex-col space-y-4 w-full md:w-1/4">
                <KeyCreations />

                <KeyDeveloperGuide />

                <KeyAdGuide />
            </div>
        </div>
    )
}

export default KeyDashboardTab
