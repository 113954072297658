import Payload from "@api/Payload"
import GetPasteAnalytics from "@features/keys/api/models/responses/GetPasteAnalytics"
import GetKeyEnabledPaste from "@features/keys/api/models/responses/GetKeyEnabledPaste"
import { API, body, header } from "@util/Api"
import KeyEnabledPaste from "@features/keys/api/models/KeyEnabledPaste"

/**
 * Retrieve all of a user's pastes who have the key system enabled.
 *
 * @param sessionToken The session token.
 * @param page The page of paste keys.
 * @param keysOnly If the analytics part should be ignored.
 */
export const getPasteKeyAnalytics = async (
    sessionToken: string,
    page: number,
    keysOnly: boolean = false
): Promise<GetPasteAnalytics> => {
    const request = await API.get(
        `/paste/key/manage?page=${page}&keysOnly=${keysOnly}`,
        header(sessionToken)
    )

    const { message, payload } = request.data as Payload<GetPasteAnalytics>

    if (request.status === 200 && payload) {
        return payload
    } else {
        return Promise.reject(message)
    }
}

/**
 * Enable key system for a paste.
 * @param sessionToken The session token.
 * @param name The name for the key.
 * @param pasteID The paste to enable the system for.
 * @param expirationInHours The expiration time (in hours).
 * @param adConfig The ad config for this key.
 */
export const enablePaste = async (
    sessionToken: string,
    name: string,
    pasteID: string,
    expirationInHours?: number,
    adConfig?: number
): Promise<GetKeyEnabledPaste> => {
    const msInHour = 3.6e6
    const request = await API.put(
        `/paste/key/manage`,
        body({
            pasteID,
            name,
            expiration: (expirationInHours ?? 24) * msInHour,
            adConfig,
        }),
        header(sessionToken)
    )
    const { payload, message }: Payload<GetKeyEnabledPaste> = request.data

    return request.status === 200 && payload ? payload : Promise.reject(message)
}

/**
 * Disable the key system for a paste.
 * @param sessionToken The session token.
 * @param pasteID The paste to disable the system for.
 */
export const disablePaste = async (sessionToken: string, pasteID: string) => {
    const request = await API.delete(
        `/paste/key/manage?pasteID=${pasteID}`,
        header(sessionToken)
    )

    const { message }: Payload<undefined> = request.data

    if (request.status !== 200) return Promise.reject(message)
}

/**
 * Set a paste key's expire time.
 * @param sessionToken The session token.
 * @param pasteID The paste ID to modify.
 * @param expireTimeHours The amount of hours in expire time.
 */
export const setPasteKeyExpireTime = async (
    sessionToken: string,
    pasteID: string,
    expireTimeHours: number
) => {
    const request = await API.post(
        `/paste/key/manage`,
        body({ pasteID, key: "expireTime", value: expireTimeHours }),
        header(sessionToken, true)
    )

    const { message }: Payload<undefined> = request.data

    if (request.status !== 200) {
        return Promise.reject(message)
    }
}

/**
 * Set a paste key's name.
 * @param sessionToken The session token.
 * @param pasteID The paste ID to modify.
 * @param keyName The new name for the key.
 */
export const setPasteKeyName = async (
    sessionToken: string,
    pasteID: string,
    keyName: string
) => {
    const request = await API.post(
        `/paste/key/manage`,
        body({ pasteID, key: "name", value: keyName }),
        header(sessionToken, true)
    )

    const { message }: Payload<undefined> = request.data

    if (request.status !== 200) {
        return Promise.reject(message)
    }
}

/**
 * Set a paste key's ad config.
 * @param sessionToken The session token.
 * @param pasteID The paste ID to modify.
 * @param adConfig The new ad config for the paste key. Leave blank for account defaults
 */
export const setPasteKeyAdConfig = async (
    sessionToken: string,
    pasteID: string,
    adConfig?: number
) => {
    const request = await API.post(
        `/paste/key/manage`,
        body({ pasteID, key: "adconfig", value: adConfig }),
        header(sessionToken, true)
    )

    const { message }: Payload<undefined> = request.data

    if (request.status !== 200) {
        return Promise.reject(message)
    }
}

/**
 * Get a paste key's details.
 * @param sessionToken The session token.
 * @param pasteID The paste key to find
 */
export const getPasteKey = async (
    sessionToken: string,
    pasteID: string
): Promise<KeyEnabledPaste> => {
    const request = await API.get(
        `/paste/key/manage?pasteID=${pasteID}`,
        header(sessionToken)
    )

    const body: Payload<KeyEnabledPaste> = request.data

    if (request.status === 200 && body.payload) {
        return body.payload
    } else {
        return Promise.reject(body.message)
    }
}
