import { LoginResponse } from "@features/users/login/api/responses/LoginResponse"
import { API, header, body, contentHeaders } from "@util/Api"
import Payload from "@api/Payload"

/**
 * Get the MFA code
 * @param sesToken The user's session token.
 */
export const getMfaCode = async (sesToken: string): Promise<string> => {
    const mfaCode = await API.post(
        `/2fa`,
        body({ value: true }),
        header(sesToken, true)
    )
    const payload: Payload<string> = mfaCode.data

    if (mfaCode.status === 200 && payload.message) {
        return payload.message
    } else {
        return Promise.reject(payload.message)
    }
}

/**
 * Change the current MFA value.
 *
 * @param sesToken The session token.
 * @param value The new MFA status
 * @param code The MFA code to authorize the request.
 */
export const toggleMfa = async (
    sesToken: string,
    value: boolean,
    code: string
): Promise<undefined> => {
    const toggleMfaResponse = await API.post(
        "/2fa",
        body({ value, code }),
        header(sesToken, true)
    )

    if (toggleMfaResponse.status !== 200)
        return Promise.reject(toggleMfaResponse.data.message)
}

/**
 * Finalize an MFA login.
 *
 * @param userID The userID returned in the initial request.
 * @param code The user's code.
 * @param verification The verification code returned in the initial request.
 * @see login
 */
export const mfaLogin = async (
    userID: string,
    code: string,
    verification: string
): Promise<LoginResponse> => {
    const loginRequest = await API.post(
        "/security",
        body({ userID, code, verification }),
        contentHeaders()
    )

    const { payload, message }: Payload<LoginResponse> = loginRequest.data

    if (loginRequest.status === 200 && payload) {
        return payload
    } else {
        return Promise.reject(message)
    }
}
